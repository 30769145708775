@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: yekan-800;
    src: url("./assets/fonts/iranyekanwebextraboldfanum.ttf");
}

@font-face {
    font-family: yekan-700;
    src: url("./assets/fonts/iranyekanwebboldfanum.ttf");
}

@font-face {
    font-family: yekan-500;
    src: url("./assets/fonts/iranyekanwebregular\(fanum\).ttf");
}

@font-face {
    font-family: yekan-400;
    src: url("./assets/fonts/iranyekanweblight\(fanum\).ttf");
}

@font-face {
    font-family: yekan-bakh-600;
    src: url("./assets/fonts/YekanBakh-Medium.woff");
}

@font-face {
    font-family: yekan-bakh-500;
    src: url("./assets/fonts/YekanBakh-Regular.woff");
}

@font-face {
    font-family: peyda-web;
    src: url("./assets/fonts/PeydaWeb-SemiBold.woff");
}

@layer base {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        @apply text-gray-700;
    }

    h1 {
        @apply text-17 font-yekan-800 sm:text-15;
    }

    h2 {
        @apply text-base font-yekan-800;
    }

    a {
        @apply list-none;
    }

    button,
    input {
        @apply outline-none;
    }
    button {
        @apply text-white border-0 cursor-pointer;
    }

    input,
    .custom-input {
        @apply text-sm text-gray-500 bg-white border-2 border-gray-200 shadow-input font-yekan-500;
    }

    input::placeholder {
        @apply text-xs text-gray-500;
    }

    input,
    input[type="text"],
    input[type="password"],
    input[type="email"],
    .custom-input {
        @apply w-full h-[50px] rounded-2xl;
    }

    .custom-input:disabled {
        cursor: not-allowed;
    }
}

@layer components {
    .active-tab {
        @apply bg-white text-secondary rounded-2xl shadow-box;
    }
    .box {
        @apply bg-white rounded-3xl shadow-box;
    }

    .arrow_box {
        @apply px-3 py-1.5 text-xs text-white rounded-2xl bg-secondary;
    }

    .submenu {
        @apply flex gap-x-3 p-2.5 items-center hover:mr-5 hover:duration-300 duration-300 hover:transition-all 
    font-yekan-500 text-xs transition-all  hover:text-primary text-gray-600 cursor-pointer;
    }

    .table-header {
        @apply text-gray-600 bg-gray-100 border border-gray-300;
    }

    .table-common-rows {
        @apply flex items-center px-6 py-3 text-right rounded-2xl text-13 font-yekan-700 sm:px-3 xs:text-xs;
    }

    .table-body-rows {
        @apply mt-3 text-gray-700 bg-white border-gray-200 shadow-input sm:text-xs;
    }

    .icon-size {
        @apply w-[22px] h-[22px];
    }

    .border-bottom {
        @apply pb-3 relative hover:text-secondary cursor-pointer transition duration-300
               hover:transition before:transition
                 hover:before:transition 
                before:w-full before:-bottom-[1.5px] before:hidden hover:before:block
                 before:absolute before:bg-secondary before:h-[2.5px] before:rounded-full;
    }
}
/* START ==> Create custom checkbox */
input[type="checkbox"],
input[type="radio"] {
    appearance: none;
    background-color: #fff;
    width: 17px;
    height: 17px;
    border: 2px solid #e6e9f8;
    box-shadow: 0px 5px 20px -7px #4c5686;
    border-radius: 4px;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

input[type="checkbox"]::before,
input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #2aafbe;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before,
input[type="radio"]:checked::before {
    transform: scale(1);
}

/* END ==> Create custom checkbox */

input[type="file"]::file-selector-button {
    display: none;
}

/* START ==> Customized calendar */
.rmdp-container {
    display: flex !important;
}

.rmdp-wrapper {
    font-family: yekan-500;
}

.calendar-input {
    border: none;
    width: 150px !important;
    height: 32px !important;
    margin: 2px 8px;
    font-size: 13px;
    font-family: yekan-400;
}
/* END ==> Customized calendar */

/* START ==> Custome Scrollbar */
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c3c3c3;
    border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #8e8d8d;
}

/* END ==> Custome Scrollbar */

/* START ==> Animation for contract circle */
.animation-circle {
    animation: circle 0.7s ease-out infinite alternate-reverse;
    transition: 0.3s;
}

@keyframes circle {
    0% {
        scale: 0.5;
        opacity: 0.3;
    }
    100% {
        scale: 1.2;
    }
}

/* END ==> Animation for contract circle */

/* React-select style(arrow) */
.css-1dimb5e-singleValue {
    color: #7a83ae !important;
    font-size: 14px;
}

#react-select-for-all-users-admin .css-12slyiy-control,
#react-select-for-all-users-admin .css-odoito-ValueContainer,
#react-select-for-all-users-admin .css-1epq2rv-control {
    height: 40px !important;
    padding-right: 10px !important;
}

#react-select-for-all-users-admin .css-odoito-ValueContainer,
#react-select-for-all-users-admin .css-qbdosj-Input input {
    height: 40px !important;
    padding: 0px !important;
}

#react-select-for-all-users-admin .css-1dimb5e-singleValue {
    font-size: 12px !important;
}

/* Style =>> Rotate attachment icon in the view-ticket page */
.rotation {
    transform: rotateY(-180deg) rotateZ(0deg);
}

/* Style for phon number in toast => "093******89"  */
.rtl-dir {
    direction: ltr !important;
    unicode-bidi: plaintext !important;
}

/* Toast Icon */
.Toastify__toast-icon {
    display: flex;
    align-items: center;
}

/* Start ==> Leaderboard Select Options */

#leaderboard-top-account .css-12slyiy-control,
#leaderboard-top-account .css-1epq2rv-control,
.leaderboard-platform .css-12slyiy-control,
.leaderboard-platform .css-1epq2rv-control {
    padding: 0 5px;
}

#leaderboard-top-account .css-12slyiy-control,
#leaderboard-top-account .css-1epq2rv-control {
    min-width: 100px;
    width: fit-content;
    border-radius: 21px;
}

.leaderboard-platform {
    min-width: 220px;
}

.leaderboard-platform .css-12slyiy-control,
#leaderboard-top-account .css-12slyiy-control {
    width: 100%;
    border: 1px solid #e6e9f8;
    border-radius: 21px;
}

.leaderboard-platform .css-1epq2rv-control {
    border-radius: 21px;
}

.leaderboard-platform .css-12slyiy-control:hover,
#leaderboard-top-account .css-12slyiy-control:hover {
    border: 1px solid #e6e9f8;
}

#leaderboard-top-account .css-1dimb5e-singleValue,
.leaderboard-platform .css-1dimb5e-singleValue {
    font-size: 12px;
    color: #4c5686 !important;
}

/* End ==> Leaderboard Select Options */
